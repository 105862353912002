import {
    deleteDataById,
    getData,
    getDataBydId,
    getExcel,
    getPaginatedData,
    insertData,
    patchData,
} from './commonService';

export default class InvoiceService {


    #defaultUrl = "/api/Invoice";
    #defaultResourceName = "Fatura";

    async getAllInvoices() {
        return await getPaginatedData(this.#defaultUrl, this.#defaultResourceName);
    }

    async getInputInvoicesWithProjectIdWithInvoiceType(projectId, invoiceType, period) {
        return await getPaginatedData(this.#defaultUrl + "/query?ProjectId=" + projectId + "&InvoiceType=" + invoiceType + "&Period=" + period, this.#defaultResourceName);
    }

    async getInputInvoicesWithProjectId(projectId, period) {
        return await getPaginatedData(this.#defaultUrl + "/query?ProjectId=" + projectId + "&Period=" + period, this.#defaultResourceName);
    }

    async getInvoicesWithProjectIdWithPeriodExcel(projectId, period) {
        return await getExcel(this.#defaultUrl + "/excel?ProjectId=" + projectId +  "&Period=" + period, this.#defaultResourceName);
    }

    async getInvoicesWithProjectIdWithPeriodWithInvoiceTypeExcel(projectId, invoiceType, period) {
        return await getExcel(this.#defaultUrl + "/excel?ProjectId=" + projectId + "&InvoiceType=" + invoiceType + "&Period=" + period, this.#defaultResourceName);
    }

    async getInvoiceReports(projectId, period) {
        return await getData(this.#defaultUrl + "/reports?ProjectId=" + projectId + "&Period=" + period, this.#defaultResourceName);
    }

    async getInvoiceById(invoiceId) {
        return await getDataBydId(this.#defaultUrl, invoiceId, this.#defaultResourceName);
    }

    async insertInvoice(dataItem) {
        const createData = {
            "companyId": dataItem.companyId,
            "documentDate": dataItem.documentDate,
            "documentNumber": dataItem.documentNumber,
            "documentNumber2": dataItem.documentNumber2,
            "invoiceTotal": dataItem.invoiceTotal,
            "taxTotal": dataItem.taxTotal,
            "totalWithTax": dataItem.totalWithTax,
            "companyName": dataItem.companyName,
            "category": dataItem.category,
            "description": dataItem.description,
            "invoiceType": dataItem.invoiceType,
            "documentType": dataItem.documentType,
            "period": dataItem.period,
            "projectId": dataItem.projectId,
            "managerUserId": dataItem.managerUserId
        }
        return await insertData(this.#defaultUrl, createData, this.#defaultResourceName);
    }




    async updateInvoice(invoiceId, dataItem) {
        const patchDocument = [
            {"op": "replace", "path": "companyId", "value": dataItem.companyId},
            {"op": "replace", "path": "isActive", "value": dataItem.isActive},
            {"op": "replace", "path": "documentDate", "value": dataItem.documentDate},
            {"op": "replace", "path": "documentNumber", "value": dataItem.documentNumber},
            {"op": "replace", "path": "documentNumber2", "value": dataItem.documentNumber2},
            {"op": "replace", "path": "invoiceTotal", "value": dataItem.invoiceTotal},
            {"op": "replace", "path": "taxTotal", "value": dataItem.taxTotal},
            {"op": "replace", "path": "isPaid", "value": dataItem.isPaid},
            {"op": "replace", "path": "totalWithTax", "value": dataItem.totalWithTax},
            {"op": "replace", "path": "companyName", "value": dataItem.companyName},
            {"op": "replace", "path": "category", "value": dataItem.category},
            {"op": "replace", "path": "description", "value": dataItem.description},
            {"op": "replace", "path": "invoiceType", "value": dataItem.invoiceType},
            {"op": "replace", "path": "period", "value": dataItem.period},
            {"op": "replace", "path": "documentType", "value": dataItem.documentType},
        ];

        return await this.patchInvoice(invoiceId, patchDocument);
    }

    async updateDocumentFile(invoiceId, dataItem) {
        const patchDocument = [
            {"op": "replace", "path": "documentFile", "value": dataItem.documentFile},
        ];

        return await this.patchInvoice(invoiceId, patchDocument);
    }


    async updateApproveStatus(invoiceId, newStatus) {
        const patchDocument = [
            {"op": "replace", "path": "isApproved", "value": newStatus},
        ];

        return await this.patchInvoice(invoiceId, patchDocument);
    }
    async deleteInvoiceDocument(fileName)
    {
        const createData = {
            "fileName": fileName
        };
        return await insertData("/api/Upload/delete-static-image?fileName=" + fileName, createData, this.#defaultResourceName);
    }


    async deleteById(invoiceId) {
        return await deleteDataById(this.#defaultUrl, invoiceId, this.#defaultResourceName);
    }

    async patchInvoice(id, patchDocument) {
        return await patchData(this.#defaultUrl, id, patchDocument, this.#defaultResourceName);
    }
}